import React, { useState } from "react";
import GlGIFRatatingObject from "../assets/GlGIFRatatingObject.gif";
import ContactOpen from "../assets/contact-open.svg";
import Contact from "../assets/contact.svg";
import { Modal } from "@mui/material";
import ContactUs from "../screens/ContactUs";
import ThreeD1 from "../worldOf3D/ThreeD1";

function Hero() {
  const [contactHovered, setContactHovered] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <section className="mt-[20px] mb-[89px] flex flex-col items-center justify-center lg:flex-row lg:justify-evenly">
      {/* Main Content */}
      <div className="flex max-h-full flex-col justify-center px-[10px] lg:w-1/2">
        <h1 className="whitespace-nowrap text-[32px] font-[600] leading-[48px] lg:text-[55px] lg:leading-[82px] ">
          We can get you that site
        </h1>
        <p className="mb-[12px] text-[10px] font-[400] lg:text-[16px]">
          We are here to help you build a top-notch website on a low budget
        </p>

        {/* Contact Button (Mobile) */}
        <a
          href="https://letsconnet.com/contact"
          className="flex max-w-[133px] place-content-center self-center whitespace-nowrap rounded-2xl bg-[#1D0CBA] px-[35px] py-[10px] text-[16px] font-[500] leading-[24px] text-white underline lg:hidden"
        >
          Contact Us
        </a>

        {/* Contact Button (Desktop) */}
        <a
          href="https://letsconnet.com/contact"
          // onClick={handleOpenModal}
          onMouseOver={() => {
            setContactHovered(true);
          }}
          onMouseLeave={() => {
            setContactHovered(false);
          }}
          className="hidden text-[16px] font-[500] leading-[24px] text-white lg:block lg:max-w-[288px]"
        >
          {contactHovered ? (
            <img alt="" className="h-full w-full" src={ContactOpen} />
          ) : (
            <img alt="" className="h-full w-full" src={Contact} />
          )}
        </a>
      </div>

      {/* GlGIFRatatingObject */}
      <img
        alt=""
        className="max-h-[164px] max-w-[152px] lg:mb-0 lg:mt-0  lg:max-h-[512px]  lg:w-1/2 lg:max-w-[477px]"
        src={GlGIFRatatingObject}
      />

      {/* 3D Component (Mobile) */}
      <div className="block lg:hidden">
        <ThreeD1 />
      </div>

      {/* 3D Component (Desktop) */}
      <div className="hidden lg:block relative middle-4 left-0 right-20">
        <ThreeD1 />
      </div>

      {/* ContactUs Modal  Not used Yet*/}
      <Modal open={openModal} onClose={handleCloseModal}>
        <ContactUs open={openModal} handleClose={handleCloseModal} />
      </Modal>
    </section>
  );
}

export default Hero;

// import React, { useState } from "react";
// import animation from "../assets/animation.svg";
// import ContactOpen from "../assets/contact-open.svg";
// import Contact from "../assets/contact.svg";
// import { Modal } from "@mui/material";
// import ContactUs from "../screens/ContactUs";
// import ThreeD1 from "../worldOf3D/ThreeD1";

// function Hero() {
//   const [contactHovered, setContactHovered] = useState(false);
//   const [openModal, setOpenModal] = useState(false);

//   const handleOpenModal = () => {
//     setOpenModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//   };

//   return (
//     <section className="mt-[20px] mb-[89px] flex flex-col items-center justify-center lg:flex-row lg:justify-evenly">
//       {/* Main Content */}
//       <div className="flex max-h-full flex-col justify-center px-[10px] lg:w-1/2">
//         <h1 className="whitespace-nowrap text-[32px] font-[600] leading-[48px] lg:text-[55px] lg:leading-[82px] ">
//           We can get you that site
//         </h1>
//         <p className="mb-[12px] text-[10px] font-[400] lg:text-[16px]">
//           We are here to help you build a top-notch website on a low budget
//         </p>

//         {/* Contact Button (Mobile) */}
//         <a
//           href="https://letsconnet.com/contact"
//           className="flex max-w-[133px] place-content-center self-center whitespace-nowrap rounded-2xl bg-[#1D0CBA] px-[35px] py-[10px] text-[16px] font-[500] leading-[24px] text-white underline lg:hidden"
//         >
//           Contact Us
//         </a>


//         {/* Contact Button (Desktop) */}
//         <a
//           href="https://letsconnet.com/contact"
//           // onClick={handleOpenModal}
//           onMouseOver={() => {
//             setContactHovered(true);
//           }}
//           onMouseLeave={() => {
//             setContactHovered(false);
//           }}
//           className="hidden text-[16px] font-[500] leading-[24px] text-white lg:block lg:max-w-[288px]"
//         >
//           {contactHovered ? (
//             <img alt="" className="h-full w-full" src={ContactOpen} />
//           ) : (
//             <img alt="" className="h-full w-full" src={Contact} />
//           )}
//         </a>
//       </div>

//       {/* Animation SVG */}
//       <img
//         alt=""
//         className="max-h-[164px] max-w-[152px] lg:mb-0 lg:mt-0  lg:max-h-[512px]  lg:w-1/2 lg:max-w-[477px]"
//         src={animation}
//       />

//       {/* 3D Component (Mobile) */}
//       <div className="block lg:hidden">
//         <ThreeD1 />
//       </div>

//   {/* 3D Component (Desktop) */}
// <div className="hidden lg:block relative middle-4 left-0 right-20">
//   <ThreeD1 />
// </div>

//       {/* ContactUs Modal  Not used Yet*/}
//       <Modal open={openModal} onClose={handleCloseModal}>
//         <ContactUs open={openModal} handleClose={handleCloseModal} />
//       </Modal>
//     </section>
//   );
// }

// export default Hero;

