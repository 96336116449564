import React from "react";
import { Modal, Box, Grid, List, ListItem, Typography, ListSubheader, Button } from "@mui/material";

const CareersModal = ({ openCareersModal, handleCloseCareersModal }) => {
    return (
        <Modal open={openCareersModal} onClose={handleCloseCareersModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "90vw",
                    maxHeight: "90vh",
                    overflowY: "auto",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Button
                    variant="text"
                    onClick={handleCloseCareersModal}
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        fontSize: "1.5rem",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    }}
                >
                    X
                </Button>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center" color="black" gutterBottom>
                            Job Openings
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <List
                            subheader={<ListSubheader style={{ color: "black" }} component="div" id="nested-list-subheader">
                                Job Title: GLJ1-Sports Writer
                            </ListSubheader>}
                        >
                            <ListItem><strong>Company: </strong>Galore Land services</ListItem>
                            <ListItem><strong>Location:</strong> Remote</ListItem>
                            <ListItem><strong>Job Type:</strong> Full Time</ListItem>
                            <ListItem><strong>Job Level:</strong> Entry Level</ListItem>
                            <ListItem><strong>Rate:</strong> $18.00/hr.</ListItem>
                            <ListItem><strong>Job Requirements: </strong>Must be able to write about sports. Must be able to collect daily sports news on players and teams.</ListItem>
                            <ListItem><strong>About us:</strong> Galore Land services Sports is a self-motivated and innovative media system dedicated to providing top-quality sports content to our audience. We are passionate about delivering attractive and astute stories that capture enthusiasm and the sports world core. As we continue to expand our team, we are seeking a talented and enthusiastic Sportswriter to join us in delivering exceptional sports coverage.</ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default CareersModal;
