import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";

function OurServices() {
  return (
    <div className="flex flex-col items-center md:bg-transparent">
      <section className="flex w-full flex-col justify-center " style={{marginTop: "20px"}}>
        <h1 className="text-center text-[32px] font-[600] leading-[48px] text-white lg:text-[40px] lg:leading-[60px] ">
          Our Services
        </h1>
        <p className="text-center text-[16px] font-[400] text-white">
          See the services we offer , get them secured, affordable and highly
          useable
        </p>
      </section>
      <section className="mt-[10vh] flex w-full min-w-[100vw] flex-col items-center justify-center  bg-[#030115] ">
        <div className="flex max-w-[250px] flex-col items-center gap-[20px] bg-black px-[40px] py-[10px] lg:max-w-[85vw] lg:flex-row lg:overflow-x-auto lg:overflow-y-hidden">
          {[
            {
              title: "Letsconnet",
              img: img1,
              description:
                "A place a where perfect match and friends are found",
              link: "https://letsconnet.com",
            },
            {
              title: "African Galore App",
              img: img5,
              description: "Where Africans Meet",
              link: "https://africangalore.com",
            },
            {
              title: "Cyber Security",
              description:
                "We offer maximum Protection of your computer from thieves and damages ",
              img: img2,
            },
            {
              title: "Development",
              description:
                "We provide good digital visual detailing website with smooth functionality and secured systems",
              img: img3,
            },
            {
              title: "Tax Accounting",
              description: " Maximum tax return is our promise",
              img: img4,
            },
          ].map(({ title, img, link, description }) => (
            <a
              href={link}
              className="flex w-full min-w-[200px]  flex-col items-center justify-center lg:max-h-[200px] lg:min-h-[200px]"
              key={""}
            >
              <div className="relative flex flex-col justify-center">
                <img alt="" className="aspect-square " src={img} />
                <div className=" absolute flex h-full w-full items-center justify-center bg-black text-center text-white opacity-0 hover:flex  lg:hover:opacity-100 lg:animate-fade-out lg:hover:animate-fade-in">
                  {description && <h1>{description}</h1>}
                </div>
              </div>
              <h1 className="text-white">{title}</h1>
              {description && (
                <h1 className="text-white lg:hidden">{description}</h1>
              )}
            </a>
          ))}
        </div>
      </section>
    </div>
  );
}

export default OurServices;
