import React from "react";
import "./EmployeeCard.css";
import { Grid, Typography } from "@mui/material";

const EmployeeCard = ({ src, name, title, info }) => {
  return (
    <Grid
      container
      item
	    spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}	
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
		
      }}
    >
        <Grid  item xs={4}   >
          <Grid container item xs={12} >
            <img
              src={src}
              style={{
                height: "250px",
                backgroundColor: "white",
              }}
            />
            <Typography

              variant="h6"
			  fontWeight={"bold"}
            p={2}			  
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}

        > 
         <Typography
            variant="h5"
             fontWeight={"bold"}
            // p={2}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            p={2}
            style={{
              overflowY: "scroll",
            }}
          >
            {info}
          </Typography>
        </Grid> 
    </Grid>
  );
};

export default EmployeeCard;
