import React, { useEffect } from "react";
import { Modal, Button, Grid, TextField, Typography, Box } from "@mui/material";
import { loadCSS } from "fg-loadcss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import MdPhone from "@mui/icons-material/Phone";
import Chip from "@mui/material/Chip";
import EmailIcon from "@mui/icons-material/Email";

const theme = createTheme({
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          boxSizing: "content-box",
          padding: 3,
          fontSize: "1.125rem",
        },
      },
    },
  },
});

const ContactUs = ({ open, handleClose }) => {
  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.14.0/css/all.css",
      document.querySelector("#font-awesome-css") || document.head.firstChild,
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ height: "100px" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              minWidth: 300,
              overflow: "auto",
              // maxWidth: "80vw", // Adjust maximum width for responsiveness
            }}
          >
            <Button
              variant="text"
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: "0",
                right: "0",
                fontSize: "1.5rem", // Adjust the font size to make it larger
                color: "black", // Set the color to black
                "&:hover": {
                  backgroundColor: "transparent", // Remove hover background color
                },
              }}
            >
              X
            </Button>
            <Grid
              container
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <div>
                <Typography variant="h4">Connect With US</Typography>
              </div>

              <div
                style={{
                  display: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  height: "95%",
                }}
              >
                <Grid
                  item
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item justifyContent="center">
                    <ThemeProvider theme={theme}>
                      <Chip
                        icon={<Icon className="fas fa-phone-alt" />}
                        label="818-523-6270"
                      />
                      <Chip
                        icon={<EmailIcon />}
                        label="galorelandservicesllc@gmail.com"
                      />
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "95%",
                  height: "95%",
                }}
              >
                <Typography>
                  <p>
                    It will be great to hear from you! Please give us your
                    contact information in the form below and we will get back
                    to you in earnest. Thank you for contributing! (*Please note
                    that the fields with stars are required)
                  </p>
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    width: "95%",
                    height: "90%",
                  }}
                >
                  <TextField 
                    type="email" 
                    id="email" 
                    label="Email"
                    sx={{ marginBottom: "10px" }} // Add some bottom margin for spacing
                  />
                  <TextField
                    type="First Name"
                    id="First Name"
                    label="First Name"
                    sx={{ marginBottom: "10px" }} // Add some bottom margin for spacing
                 />
                  <TextField
                    type="Middle Name"
                    id="Middle Name"
                    label="Middle Name"
                    sx={{ marginBottom: "10px" }} // Add some bottom margin for spacing
                  />
                  <TextField
                    type="Last Name"
                    id="Last Name"
                    label="Last Name"
                    sx={{ marginBottom: "10px" }} // Add some bottom margin for spacing
                  />
                  <TextField
                    type="Questions and Comments"
                    id="Questions and Comments"
                    label="Questions and Comments"
                    sx={{ marginBottom: "10px" }} // Add some bottom margin for spacing
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      // backgroundColor: 'pink',
                    }}
                  >
                    <Button variant="contained">Contribute</Button>
                    <Button variant="contained">Reset</Button>
                  </div>
                </div>
              </div>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ContactUs;
