import React, { useState } from "react";
import ContactUs from "../screens/ContactUs";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import youtube from "../assets/youtube.svg";
import { Modal, Button } from "@mui/material";
import About from "../screens/About";
import Careers from "../screens/Careers";
import CareersModal from "../glModals/CareersModal";


const Footer = () => {
  const [openCareersModal, setOpenCareersModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openAboutModal, setOpenAboutModal] = useState(false);

  const handleOpenCareersModal = () => {
    setOpenCareersModal(true);
  };

  const handleCloseCareersModal = () => {
    setOpenCareersModal(false);
  };
  const handleOpenContactModal = () => {
    setOpenContactModal(true);
  };

  const handleCloseContactModal = () => {
    setOpenContactModal(false);
  };

  const handleOpenAboutModal = () => {
    setOpenAboutModal(true);
  };

  const handleCloseAboutModal = () => {
    setOpenAboutModal(false);
  };

  return (
    <footer className="w-full self-end justify-self-end bg-black p-2">
      <div className="grid grid-cols-3">
        <div className="flex h-full flex-col items-center justify-start text-white">
          <div className="flex flex-col">
            <p className="text-[11px] font-[600] leading-[16px] lg:text-[20px] lg:font-[800] lg:leading-[30px]">
              Socials
            </p>
            <div className="flex gap-[10px]">
              {[instagram, facebook, twitter, youtube].map((img, i) => (
                <div key={i} className="max-h-[25px] max-w-[25px]">
                  <img alt="" src={img} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex h-full justify-center text-white">
          <div className="flex flex-col">
            <p className="text-[11px] font-[600] leading-[16px] lg:text-[20px] lg:font-[800] lg:leading-[30px]">
              Products
            </p>
            {[
              { title: "Letsconnet", link: "https://letsconnet.com" },
              {
                title: "African Galore App",
                link: "https://africangalore.com",
              },
            ].map(({ title, link }) => (
              <div key={title}>
                {link ? (
                  <a href={link} key={title}>
                    {title}
                  </a>
                ) : (
                  <div>{title}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex h-full flex-col items-center text-white">
          <div className="flex flex-col">
            <p className="text-[11px] font-[600] leading-[16px] lg:text-[20px] lg:font-[800] lg:leading-[30px]">
              Our Company
            </p>
            {[
              { title: "About Us", onClick: handleOpenAboutModal },
              { title: "Jobs", onClick: handleOpenCareersModal  },
              { title: "Contact Us", link: "https://letsconnet.com/contact" },
              {
                title: "African Galore",
                link: "https://africangalore.com/asa-sc_aswa-sc",
              },
            ].map(({ title, link, onClick }) => (
              <div key={title}>
                {link ? (
                  <a href={link} key={title}>
                    <button onClick={onClick} className="text-white">
                      {title}
                    </button>
                  </a>
                ) : (
                  <div>
                    <button onClick={onClick} className="text-white">
                      {title}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
 
      </div>
      <ContactUs open={openContactModal} handleClose={handleCloseContactModal} />
      <Modal
        open={openAboutModal}
        onClose={handleCloseAboutModal}
        aria-labelledby="about-modal-title"
        aria-describedby="about-modal-description"
      >
        <About openAboutModal={openAboutModal} handleCloseAboutModal={handleCloseAboutModal} />
      </Modal>
            <Modal
        open={openCareersModal}
        onClose={handleCloseCareersModal}
        aria-labelledby="careers-modal-title"
        aria-describedby="careers-modal-description"
      >
        <CareersModal openCareersModal={openCareersModal} handleCloseCareersModal={handleCloseCareersModal} />
      </Modal>           
    </footer>
  );
};

export default Footer;

