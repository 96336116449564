import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import IRSBanner from "./components/IRSBanner";
import OurServices from "./components/OurServices";
import background from "./assets/background.svg";

export default function App() {
  return (
    <div className="relative flex h-screen min-h-screen flex-col overflow-x-hidden">
      <img
        alt=""
        className="absolute  bottom-[-15vh] z-[-10] flex h-full w-full scale-[100%] flex-col bg-black object-cover lg:bottom-[-40vh] md:left-[-10vw] md:w-[220vw] md:scale-x-[120%] "
        src={background}
      />
      <Header />
      <Hero />
      <div >
        <OurServices />
        <IRSBanner />
      </div>
      <Footer />
    </div>
  );
}
