// import React, { useState } from "react";
// import { Grid, Typography, Modal, Button, Box  } from "@mui/material";

// import EmployeeCard from "../components/EmployeeCard";
// import Dubem1 from "../assets/Employees/IMG_4606_pp.jpg";
// import Dubem2 from "../assets/Employees/IMG_4615_pp.jpg";
// import George1 from "../assets/Employees/IMG_4563.jpg";
// import Ugo1 from "../assets/Employees/Ugo1.jpg";

// const About = ({ openAboutModal, handleCloseAboutModal }) => {
//   const [showMission, setShowMission] = useState(false);

//   const handleShowMission = () => {
//     setShowMission(true);
//   };
//   return (
//     <Modal open={openAboutModal} onClose={handleCloseAboutModal}>
//       <Box>
//       <Grid container
//         justifyContent="center"
//         alignItems="center"
//         sx={{
//                position: "absolute",
//               overflow: "scroll",
//             }}
//       >
//           <Box
//             sx={{
//               bgcolor: "white",
//               boxShadow: 24,
//               p: 4,
//               minWidth: 300,
//               overflow: "scroll",
//             }}
//           >
//             <Button
//               variant="text"
//               onClick={handleCloseAboutModal}
//               sx={{
//                 position: "absolute",
//                 top: "0",
//                 right: "0",
//                 fontSize: "1.5rem", // Adjust the font size to make it larger
//                 color: "black", // Set the color to black
//                 "&:hover": {
//                   backgroundColor: "transparent", // Remove hover background color
//                 },
//               }}
//             >
//               X
//             </Button>
//         <Grid
//           item
//           xs={12}
//           display={"flex"}
//           bgcolor={"#DBA622"}
//           p={2} // Add padding to the grid item

//         >
//           <Grid container item xs={4} display={"flex"} i>
//             <Grid item xs={12}>
//               <Typography variant="h5" gutterBottom>
//                 Our Company
//               </Typography>
//               <Typography>
//                 Established in 2016, Galore Land Services Prides itself on
//                 rendering quality software Services to its clients. Our Goal is to
//                 use cutting-edge technologies to design products and Services that
//                 do not discriminate against any group of people and are accessible
//                 to individuals with disabilities.
//               </Typography>
//               <Button variant="contained" onClick={handleShowMission}>
//                 Learn More
//               </Button>
//             </Grid>
//             {showMission && (

//             <Grid
//               container
//               item
//               xs={12}
//               display="flex"
//               flexDirection={"row"}
//               justifyContent="center"
//               alignItems="center"
//               mt={2} // Add margin-top to the grid container
//             p={2} // Add padding to the grid container
//             bgcolor="#f0f0f0" // Add background color to the grid container
//             borderRadius={2} // Add border radius to the grid container
//             >
//               <div>
//                 <Typography variant="h4" gutterBottom>About Us</Typography>
//               </div>

//               <div
//                 style={{ marginLeft: "10px", marginRight: "20px"  }}
//               >
//                 <Typography variant="h5" gutterBottom>Our Mission</Typography>
//                 <Typography>
//                   We are committed to providing high-quality software services
//                   to our clients while ensuring accessibility and inclusivity
//                   for all individuals.
//                 </Typography>
//               </div>
//             </Grid>
//           )}
//           </Grid>
//           <Grid item xs={8} display={"flex"} direction={"column"}>
//             <Grid container item xs={8} marginTop={3} marginBottom={3}>
//               <Grid item xs={12}>
//                 <EmployeeCard
//                   src={Dubem1}
//                   name="Dr. Mrs. Dubem Anumba"
//                   title="Executive Director of Projects"
//                   info="Highly organized and detail-oriented software engineering professional
//                       with over 15 years of progressive experience in the entertainment and payroll industries.
//                       Felicitas is the Chief Executive Officer of Galore Land Services."
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <EmployeeCard
//                   src={George1}
//                   name="George Anumba"
//                   title="Senior Software Engineer"
//                   info="Software Engineer Guru with over 4 years
//                       experience in building interactive and responsive user interfaces.
//                       George is a Senior Programmer at Galore Land Services."
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <EmployeeCard
//                   src={Ugo1}
//                   name="Raphael Anumba"
//                   title="Software Engineer"
//                   info="Creative full Stack Web Developer, with a skill of providing innovative ideas
//                       that will make a business unique.
//                       Enjoys problem solving and improving the growth and use of applications.
//                       UCLA Full Stack Certified"
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//           </Box>
//       </Grid>
//       </Box>
//   </Modal>
//   );
// };

// export default About;
import React, { useState } from "react";
import { Card, CardContent, CardMedia, Grid, Typography, Modal, Button, Box } from "@mui/material";

import EmployeeCard from "../components/EmployeeCard";
import Dubem1 from "../assets/Employees/IMG_4606_pp.jpg";
import George1 from "../assets/Employees/IMG_4563.jpg";
import Ugo1 from "../assets/Employees/Ugo1.jpg";

const About = ({ openAboutModal, handleCloseAboutModal }) => {
  const [showMission, setShowMission] = useState(false);

  const handleShowMission = () => {
    setShowMission(true);
  };

  return (
    <Modal open={openAboutModal} onClose={handleCloseAboutModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90vw",
          height: "90vh",
          overflow: "auto",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: 4,
          p: 2,
        }}
      >
        <Button
          variant="text"
          onClick={handleCloseAboutModal}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            fontSize: "1.5rem",
            color: "black",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          X
        </Button>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom align="center">
              Our Company
            </Typography>
            <Typography paragraph align="center">
              Established in 2016, Galore Land Services prides itself on
              rendering quality software services to its clients. Our goal is to
              use cutting-edge technologies to design products and services that
              do not discriminate against any group of people and are accessible
              to individuals with disabilities.
            </Typography>
            <Button variant="contained" onClick={handleShowMission} fullWidth>
              Learn More
            </Button>
          </Grid>
          {showMission && (
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom align="center">
                About Us
              </Typography>
              <Typography variant="h5" gutterBottom align="center">
                Our Mission
              </Typography>
              <Typography align="center">
                We are committed to providing high-quality software services to
                our clients while ensuring accessibility and inclusivity for all
                individuals.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <EmployeeCard
              src={Dubem1}
              name="Dr. Mrs. Dubem Anumba"
              title="Executive Director of Projects"
              info="Highly organized and detail-oriented software engineering professional with over 15 years of progressive experience in the entertainment and payroll industries. Felicitas is the Chief Executive Officer of Galore Land Services."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EmployeeCard
              src={George1}
              name="George Anumba"
              title="Senior Software Engineer"
              info="Software Engineer Guru with over 4 years experience in building interactive and responsive user interfaces. George is a Senior Programmer at Galore Land Services."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <EmployeeCard
              src={Ugo1}
              name="Raphael Anumba"
              title="Software Engineer"
              info="Creative full Stack Web Developer, with a skill of providing innovative ideas that will make a business unique. Enjoys problem solving and improving the growth and use of applications. UCLA Full Stack Certified"
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default About;
