import React, { useState } from "react";
import Menu from "../assets/Menu.png";
import logo from "../assets/logo.png";

const Header = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick = (index) => {
    setActiveItem(activeItem === index ? null : index);
  };

  return (
    <header className="mb-[40px] flex h-[80px] w-full items-center justify-between bg-white pr-[15px] drop-shadow-lg">
      {/* Logo for Desktop View */}
      <img alt="" className="hidden lg:block" src={logo} />

      <div className="hidden pr-[7vw] md:gap-[7vw] lg:flex ">
        {[
          { name: "Home" },
          {
            name: "Products",
            links: [
              { title: "Africa Galore", link: "https://africangalore.com/asa-sc_aswa-sc" },
              { title: "Letsconnet", link: "https://letsconnet.com" },
            ],
          },
          {
            name: "Community",
            links: [
              { title: "Latest Happenings", link: "https://letsconnet.com/latestlaevents" },
              { title: "Sports", link: "https://letsconnet.com/sports" },
            ],
          },
           {/*{
            name: "Jobs",
            links: [
              { title: "Galore Careers", link: "" },
              { title: "External Jobs", link: "" },
            ],
          },
          {
            name: "More",
            links: [
              { title: "About Us", link: "" },
              { title: "Contact Us", link: "" },
            ],
          }, */}
        ].map(({ name, links }, i) => (
          <div key={i} className="relative font-bold" onClick={() => handleClick(i)}>
            <p className={`${activeItem === i && "font-[900]"} w-[5vw]`}>{name}</p>
            {links && activeItem === i && (
              <div className="absolute flex flex-col rounded-md bg-white py-4 pl-[20px] z-[10]">
                {links?.map(({ title, link }, j) => (
                  <a href={link} key={j} className="p-2 font-bold">
                    {title}
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Logo for Mobile View */}
      <img alt="" className="lg:hidden ml-auto" src={logo} />

      {/* Menu Icon and Dropdown for Mobile View */}
      <div className="absolute center-[15px] lg:hidden">
        <img alt="" className="lg:hidden cursor-pointer" src={Menu} onClick={toggleMenu} />
        {menuOpen && (
          <div className="absolute top-[80px] left-0 bg-white py-2 px-4 border border-gray-200 rounded shadow-lg">
            <ul>
              <li className="py-1">
                <a href="#" className="text-gray-800 hover:text-blue-600 font-bold">
                  Home
                </a>
              </li>
              <hr className="my-1 border-gray-200" />
              <li className="py-1">
                <a href="#" className="text-blue-600 hover:text-blue-800 font-bold" style={{ whiteSpace: "nowrap" }}>
                  Products
                </a>
                <ul>
                  <li>
                    <a href="https://africangalore.com/asa-sc_aswa-sc" className="font-bold" style={{ whiteSpace: "nowrap" }}>
                      Africa Galore
                    </a>
                  </li>
                  <li>
                    <a href="https://letsconnet.com" className="font-bold" style={{ whiteSpace: "nowrap" }}>
                      Letsconnet
                    </a>
                  </li>
                </ul>
              </li>
              <hr className="my-1 border-gray-200" />
              <li className="py-1">
                <a href="#" className="text-blue-600 hover:text-blue-800 font-bold" style={{ whiteSpace: "nowrap" }}>
                  Community
                </a>
                <ul>
                  <li>
                    <a href="https://letsconnet.com/latestlaevents" className="font-bold" style={{ whiteSpace: "nowrap" }}>
                      Latest Happenings
                    </a>
                  </li>
                  <li>
                    <a href="https://letsconnet.com/sports" className="font-bold" style={{ whiteSpace: "nowrap" }}>
                      Sports
                    </a>
                  </li>
                </ul>
              </li>
              {/* <hr className="my-1 border-gray-200" />
              <li className="py-1">
                <a href="#" className="text-blue-600 hover:text-blue-800 font-bold" style={{ whiteSpace: "nowrap" }}>
                  Jobs
                </a>
                <ul>
                  <li>
                    <a href="#" className="font-bold" style={{ whiteSpace: "nowrap" }}>
                      Galore Careers
                    </a>
                  </li>
                  <li>
                    <a href="#" className="font-bold" style={{ whiteSpace: "nowrap" }}>
                      External Jobs
                    </a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

// import React, { useState } from "react";
// import Menu from "../assets/Menu.png";
// import logo from "../assets/logo.png";

// const Header = () => {
//   const [activeItem, setActiveItem] = useState(null);
//   const [menuOpen, setMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   const handleClick = (index) => {
//     setActiveItem(activeItem === index ? null : index);
//   };

//   return (
//     <header className="mb-[40px] flex h-[80px] w-full items-center justify-between bg-white pr-[15px] drop-shadow-lg">
//       {/* Logo for Desktop View */}
//       <img alt="" className="hidden lg:block" src={logo} />

//       <div className="hidden pr-[7vw] md:gap-[7vw] lg:flex ">
//         {[
//           { name: "Home" },
//           {
//             name: "Products",
//             links: [
//               { title: "Africa Galore", link: "" },
//               { title: "Letsconnet", link: "https://letsconnet.com" },
//             ],
//           },
//           {
//             name: "Community",
//             links: [
//               { title: "Latest Happenings", link: "" },
//               { title: "Life Style", link: "" },
//             ],
//           },
//           {
//             name: "Jobs",
//             links: [
//               { title: "Galore Careers", link: "" },
//               { title: "External Jobs", link: "" },
//             ],
//           },
//           {
//             name: "More",
//             links: [
//               { title: "About Us", link: "" },
//               { title: "Contact Us", link: "" },
//             ],
//           },
//         ].map(({ name, links }, i) => (
//           <div key={i} className="relative" onClick={() => handleClick(i)}>
//             <p className={`${activeItem === i && "font-[900]"} w-[5vw]`}>{name}</p>
//             {links && activeItem === i && (
//               <div className="absolute flex flex-col rounded-md bg-white py-4 pl-[20px] z-[10]">
//                 {links?.map(({ title, link }, j) => (
//                   <a href={link} key={j} className="p-2">
//                     {title}
//                   </a>
//                 ))}
//               </div>
//             )}
//           </div>
//         ))}
//       </div>

//       {/* Logo for Mobile View */}
//       <img alt="" className="lg:hidden ml-auto" src={logo} />

//       {/* Menu Icon and Dropdown for Mobile View */}
//       <div className="absolute center-[15px] lg:hidden">
//         <img alt="" className="lg:hidden cursor-pointer" src={Menu} onClick={toggleMenu} />
//         {menuOpen && (
//           <div className="absolute top-[80px] left-0 bg-white py-2 px-4 border border-gray-200 rounded shadow-lg">
//             <ul>
//               <li className="py-1">
//                 <a href="#" className="text-gray-800 hover:text-blue-600">
//                   Home
//                 </a>
//               </li>
//               <li className="py-1">
//                 <a href="#" className="text-gray-800 hover:text-blue-600">
//                   Products
//                 </a>
//               </li>
//               <li className="py-1">
//                 <a href="#" className="text-gray-800 hover:text-blue-600">
//                   Community
//                 </a>
//               </li>
//               <li className="py-1">
//                 <a href="#" className="text-gray-800 hover:text-blue-600">
//                   Jobs
//                 </a>
//               </li>
//             </ul>
//           </div>
//         )}
//       </div>
//     </header>
//   );
// };

// export default Header;
