import IRS from "../assets/IRS-Badge.svg";
import React from "react";

const IRSBanner = () => {
  return (
    <section   className=" py-[75px] flex  w-full flex-col items-center justify-center bg-[#030115] ">
      <h1 className="text-center text-[32px] font-[600] leading-[48px] text-white ">
        Our Partners
      </h1>
      <a href="https://www.irs.gov/">
        <img
          alt="IRS Badge"
          className="flex max-h-[41px] max-w-[206px] lg:max-h-[79px] lg:max-w-[390px] "
          src={IRS}
        />
      </a>
    </section>
  );
};

export default IRSBanner;
